
import React, { useEffect, useState } from "react";
import { BuryingPointFc } from "../../../components/buryingPoint";
import { queryAllTagCategoryOfCreatorBaseForMerchant } from "../../../services/blog";
// import NavTags from './NavTags'
// import { coreatorTags } from './NavTags'

interface NavPcProps {
    navActiveId: string;
    handleNavActive: (id: string) => void;
}

const coreatorTags = [
    {
        id: "1000092", 
        name: "内容运营", 
        level: 1,
        fullNo: '12000000000',
        code:'VO00300100300108',
        key1: 'VO'
    },
    {
        id: "1000093", 
        name: "品牌策划", 
        level: 1,
        fullNo: '13000000000',
        code:'VO00300100300102',
        key1: 'VO'
    },
    {
        id: "1000094", 
        name: "教育培训", 
        level: 1,
        fullNo: '14000000000',
        code:'VO00300100300103',
        ke1: 'VO'
    },
    {
        id: "1000095", 
        name: "美容美妆", 
        level: 1,
        fullNo: '15000000000',
        code:'VO00300100300104',
        key1: 'VO'
    },
    {
        id: "1000096", 
        name: "家政服务", 
        level: 1,
        fullNo: '16000000000',
        code:'VO00300100300105',
        key1: 'VO'
    },
    {
        id: "1000097", 
        name: "生活服务", 
        level: 1,
        fullNo: '17000000000',
        code:'VO00300100300106',
        key1: 'VO'
    },
    {
        id: "1000098", 
        name: "其他", 
        level: 1,
        fullNo: '18000000000',
        code:'VO00300100300107',
        key1: 'VO'
    },
]
const NavPcFc: React.FC<NavPcProps> = (props) => {
    // const [coreatorTags,setCoreatorTags] = useState([])
    // useEffect(() => {
    //    handleCreatorCartgory()
    // }, [])
//    const handleCreatorCartgory = async ()=>{
//     const req = {}
//     const res = await queryAllTagCategoryOfCreatorBaseForMerchant(req)
//     if(res?.header?.success){
//         setCoreatorTags(res.tags)
//     }
//    }

    const {
        navActiveId,
        handleNavActive
    } = props;

  
    const onActive = {
        color: '#fff',
        backgroundColor: '#f5000a',
        borderRadius: '9px',
    }

    const offActive = {
        color: '#999',
        backgroundColor: '#fff',
        borderRadius: '9px',
    }

    return (
        <div className="nav">
            <a
                    key={0}
                    className="nav-link nav-color-0 pointer"
                    style={navActiveId == "0" ? onActive : offActive}
                    onClick={() =>{ handleNavActive(0)}}
                >
                   全部
                </a>
            {coreatorTags?.map((i) => [
                <a
                    key={i.id}
                    className="nav-link nav-color-0 pointer"
                    style={navActiveId == i.id ? onActive : offActive}
                    onClick={() => {
                        handleNavActive(i.id);
                        BuryingPointFc(i.key1 || 'VO',i.code,{action: i.name})
                    }
                    }
                >
                    {i.name}
                </a>
            ])}
        </div>
    )
}

export default NavPcFc;